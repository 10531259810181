import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

export const SCREEN_WIDTH = Dimensions.get("window").width;
export const SCREEN_HEIGHT = Dimensions.get("window").height;

// export const FONT_FAMILY = "Verdana";

const COLORS = {
  MAIN: "#EA9B8E",
  GRAY_1: "rgb(245,245,245)",
  GRAY_2: "rgb(223,223,223)",
  GRAY_4: "rgb(63,63,63)",
  TRANSPARENT_GRAY_1: "rgba(8,8,8,0.35)",
  TRANSPARENT_GRAY_2: "rgba(239,239,239,0.4)",
};

const STYLES = StyleSheet.create({
  FLEX_ROW: {
    flexDirection: "row",
  },
  ALIGN_C: {
    alignItems: "center",
  },
  ALIGN_S: {
    alignItems: "flex-start",
  },
  ALIGN_E: {
    alignItems: "flex-end",
  },
  JUSTIFY_C: {
    justifyContent: "center",
  },
  JUSTIFY_E: {
    justifyContent: "flex-end",
  },
  JUSTIFY_B: {
    justifyContent: "space-between",
  },
  TAB_TITLE: {
    marginTop: 6,
    marginHorizontal: 20,
    marginBottom: 14,
    // fontFamily: FONT_FAMILY,
    fontWeight: "bold",
    fontSize: 24,
    lineHeight: 29,
  },
  TAB_TITLE_DIVIDER: {
    backgroundColor: COLORS.GRAY_2,
    height: 2,
    marginTop: 12,
  },
});

const TEXT_STYLES = StyleSheet.create({
  FONT_TYPE_1: {
    // fontFamily: FONT_FAMILY,
    fontSize: 24,
    color: "#242126",
    fontWeight: "bold",
  },
  FONT_TYPE_2: {
    // fontFamily: FONT_FAMILY,
    fontSize: 18,
    color: "#242126",
  },
  FONT_TYPE_3: {
    // fontFamily: FONT_FAMILY,
    fontSize: 16,
    color: "#0D0505",
  },
  FONT_TYPE_4: {
    // fontFamily: FONT_FAMILY,
    fontSize: 15,
    color: COLORS.MAIN,
  },
  FONT_TYPE_5: {
    // fontFamily: FONT_FAMILY,
    fontSize: 14,
    color: "#3F3F3F",
    fontWeight: "bold",
  },
  FONT_TYPE_6: {
    // fontFamily: FONT_FAMILY,
    fontSize: 26,
    fontWeight: "bold",
  },
  FONT_TYPE_7: {
    // fontFamily: FONT_FAMILY,
    fontSize: 19,
    color: "white",
  },
  FONT_TYPE_8: {
    fontSize: 20,
    // fontFamily: FONT_FAMILY,
    lineHeight: 24,
  },
});

export { TEXT_STYLES, STYLES, COLORS };
