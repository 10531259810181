import { StatusBar } from 'expo-status-bar';
import React, { useState, useContext, useEffect } from "react";
import { Platform, DeviceEventEmitter, ActivityIndicator, Alert, TextInput, SafeAreaView, StyleSheet, TouchableOpacity } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { RedRoundedButton } from "../components/StyledButtons";
import { GrayRoundedTextInput } from "../components/StyledTextInput";
import {
  SCREEN_WIDTH,
  TEXT_STYLES,
  STYLES,
  COLORS,
  SCREEN_HEIGHT,
} from "../Util/Styles";

import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiurl } from "../Util/config.js";
import alert from '../Util/alert'

export default function LoginScreen({ navigation }) {
  const [codedisable, setCodedisable] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [stayLoggedIn, setStayLoggedIn] = useState(true);

  const signInOnPress = async () => {
    if (username.length == 0) {
      alert("Email cannot be empty");
      return;
    }
    if (password.length == 0) {
      alert("Verification Code cannot be empty");
      return;
    }
    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(username)) {
      alert("Please enter a valid Email");
      return;
    }

    DeviceEventEmitter.emit("showLoading")
    const payload = {
      email: username,
      password,
    };

    fetch(apiurl + "/lfapp/loginWithCode", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(res => res.json())
      .then((res) => {
        DeviceEventEmitter.emit("closeLoading")
        AsyncStorage.setItem("user", JSON.stringify(res.data))
        global.user = res.data
        global.jwt = res.jwt
        DeviceEventEmitter.emit("refreshUser")
        navigation.navigate('Root')
      })
      .catch(e => {
        DeviceEventEmitter.emit("closeLoading")
        console.log(e)
      })

  };
  const getVerificationCode = async () => {
    DeviceEventEmitter.emit("showLoading")

    if (username.length == 0) {
      alert("Email cannot be empty");
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;

    if (!emailRegex.test(username)) {
      alert("Please enter a valid Email");
      return;
    }

    fetch(apiurl + "/lfapp/sendLoginCode", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: username
      })
    }).then(res => res.json())
      .then((res) => {
        console.log(res)
        DeviceEventEmitter.emit("closeLoading")
        setCodedisable(true)
        Alert.alert(res.data);
      })
      .catch(e => {
        DeviceEventEmitter.emit("closeLoading")
        console.log(e)
      })
  };

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <Text style={styles.titleText}>Sign In</Text>

        <View style={styles.divider} />

        <GrayRoundedTextInput
          placeholder={"Email"}
          onChangeText={(text) => setUsername(text)}
        />

        <View style={styles.divider} />

        <TouchableOpacity onPress={() => getVerificationCode()} disabled={codedisable}>
          <View style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
            marginBottom: 20,
          }}>
            <Text style={{ color: codedisable ? "gray" : "#3c4ae2" }}>{"Get Verification Code"}</Text>
          </View>
        </TouchableOpacity>

        <TextInput
          secureTextEntry={true}
          style={styles.secureInput}
          placeholder={"Verification Code"}
          placeholderTextColor="rgba(0,0,0,0.5)"
          onChangeText={(text) => setPassword(text)}
          autoCapitalize={'none'}
          autoCorrect={false}
        />
        <RedRoundedButton text={"Sign In"} onPress={() => signInOnPress()} />
        <RedRoundedButton text={"Back"} onPress={() => { navigation.navigate('Root') }} />
      </SafeAreaView>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
  },

  titleText: {
    ...TEXT_STYLES.FONT_TYPE_6,
    ...{
      marginLeft: 24,
      textAlign: "left",
      marginRight: 6,
      marginTop: 24,
      marginBottom: 12,
    },
  },
  secureInput: {
    backgroundColor: "#EFEFEF",
    paddingVertical: 20,
    paddingHorizontal: 24,
    margin: 5,
    borderRadius: 48,
    marginHorizontal: 20,
  },
  radioButtonRow: {
    marginHorizontal: 22,
    marginTop: 42,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  createNewAccountText: {
    color: COLORS.MAIN,
    fontSize: 16,
    textDecorationLine: "underline",
    textAlign: "center",
    marginTop: 40,
  },

  divider: {
    marginVertical: 10,
  },
});
