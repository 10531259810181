import { useEffect, useState } from 'react';
import React, { StyleSheet, FlatList, TextInput, Platform, Linking, TouchableOpacity, DeviceEventEmitter, Image } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SCREEN_WIDTH, TEXT_STYLES, STYLES, COLORS, SCREEN_HEIGHT } from "../Util/Styles";
import Common from '../Util/common';
import moment from 'moment'
import { apiurl } from "../Util/config.js";
import * as ImagePicker from 'expo-image-picker';

import DateTimePicker from '@react-native-community/datetimepicker';

import alert from '../Util/alert'


export default function TabOneScreen({ navigation }: RootTabScreenProps<'TabOne'>) {
  const [orders, setOrders] = useState([])
  const [user, setUser] = useState(null)
  const [date, setDate] = useState(moment())

  useEffect(() => {
    DeviceEventEmitter.addListener('refreshUser', () => {
      AsyncStorage.getItem('user')
        .then((data) => {
          if (data) {
            let user = JSON.parse(data)
            global.user = user
            setUser(user)
            fetchDriverOrders()
          }
        })
    })

    AsyncStorage.getItem('user')
      .then((data) => {
        if (data) {
          let user = JSON.parse(data)
          global.user = user
          setUser(user)
          fetchDriverOrders()
        }
      })
  }, [])

  var fetchDriverOrders = () => {
    DeviceEventEmitter.emit("showLoading")
    let tmpDate = moment(date)
    console.log(date.format("YYYY-MM-DD"))
    console.log((tmpDate.month() + 1) + "/" + tmpDate.date() + "/" + tmpDate.year(),)
    fetch(apiurl + "/lfapp/getDriverOrderByDate", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        date: (tmpDate.month() + 1) + "/" + tmpDate.date() + "/" + tmpDate.year(),
        id: global.user._id,
      })
    }).then(res => res.json())
      .then((res) => {
        console.log(res)
        DeviceEventEmitter.emit("closeLoading")
        if (res.ok) {
          setOrders(res.data)
        } else {
          setOrders([])
        }
      })
      .catch(e => {
        DeviceEventEmitter.emit("closeLoading")
        console.log(e)
      })

  }
  var addressOnClick = (order) => {
    console.log(order)
    const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=', web: 'http://maps.google.com/?q=' });
    const latLng = `${order.lat},${order.lng}`;
    const label = order.zname;
    const url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`,
      web: `${scheme}${label}@${latLng}`,
    });
    Linking.openURL(url);
  }
  var renderStatus = (status) => {
    if (!status) {
      status = "init"
    }
    let tmpStatus = ""

    switch (status) {
      case "init":
        {
          tmpStatus = "Finish Order"
        }
        break;
      case "deliveried":
        {
          tmpStatus = "Deliveried"
        }
        break;
      default:
        break;
    }
    return tmpStatus
  }

  var finishOnPress = (data) => {
    alert(
      "Alert",
      "Confirm Deliery?",
      [
        {
          text: 'Cancel',
          onPress: () => {

          },
          style: 'cancel',
        },
        {
          text: 'OK', onPress: () => {
            DeviceEventEmitter.emit("showLoading")
            fetch(apiurl + "/lfapp/setPOrderFinished", {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ddh: data.ddh
              })
            }).then(res => res.json())
              .then((res) => {
                DeviceEventEmitter.emit("closeLoading")
                console.log(res)
                if (res.ok) {
                  setOrders(res.data)
                }
                setOrders(orders.map(item => {
                  if (item.order.length > 0 && res.data.ddh == item.order[0].ddh) {
                    item.order[0].status = res.data.status
                  }
                  return item
                }))
              })
              .catch(e => {
                DeviceEventEmitter.emit("closeLoading")
                console.log(e)
              })
          }
        }
      ],
      { cancelable: false },
    )

  }

  const pickFromCamera = async (order) => {

    // Ask the user for the permission to access the camera
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your camera!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.1
    })

    console.log(Platform.OS)

    console.log(result);


    if (!result.cancelled) {
      console.log(result.uri)
    }
    fetch(apiurl + "/lfapp/postOrderFinishImage?ddh=" + order.ddh, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        img: result.uri,
        platform: Platform.OS
      })
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        setOrders(orders.map(item => {
          if (item.order.length > 0 && res.data.ddh == item.order[0].ddh) {
            item.order[0].image = res.data.image
          }
          return item
        }))
      })
      .catch(error => {
        console.log(error);
      });

  }

  var renderItem = ({ item }) => {
    let renderedCards = []
    let order = null
    if (item.order.length > 0) {
      order = item.order[0]
      renderedCards.push(
        <View style={bookingDetailStyles.cardBG} key={order._id}>
          <View style={bookingDetailStyles.textItem}>
            <Text style={bookingDetailStyles.titleText}>Name: </Text>
            <Text style={bookingDetailStyles.text}>{order.zname}</Text>
          </View>
          <View style={bookingDetailStyles.textItem}>
            <Text style={bookingDetailStyles.titleText}>Location: </Text>

            <TouchableOpacity onPress={() => { addressOnClick(order) }}>
              <View style={{
                width: 270 * Common.WIDTH_DIVISOR,
              }}>
                <Text style={{
                  color: "#007BC3",
                  textDecorationLine: 'underline',
                }}>{order.address}</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={bookingDetailStyles.textItem}>
            <Text style={bookingDetailStyles.titleText}>Method: </Text>
            <Text style={bookingDetailStyles.text}>{order.method}</Text>
          </View>
          {order.clsm ? <View style={bookingDetailStyles.textItem}>
            <Text style={bookingDetailStyles.titleText}>Notes: </Text>
            <Text style={bookingDetailStyles.text}>{order.clsm}</Text>
          </View> : null}
          {order.image ? <Image
            style={{ width: 300 * Common.WIDTH_DIVISOR, height: 200 * Common.WIDTH_DIVISOR, alignSelf: "center", marginVertical: 10 * Common.WIDTH_DIVISOR }}
            source={{
              uri: apiurl + order.image,
            }}
          /> : null}
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity onPress={() => { pickFromCamera(order) }} >
              <View style={{
                borderRadius: 10 * Common.WIDTH_DIVISOR,
                backgroundColor: "#ea9b8e",
                width: 120 * Common.WIDTH_DIVISOR,
                height: 40 * Common.WIDTH_DIVISOR,
                justifyContent: "center",
                alignSelf: "flex-end",
                marginLeft: 20 * Common.WIDTH_DIVISOR,
                marginBottom: 15 * Common.WIDTH_DIVISOR,
                // marginRight: 20,
                alignItems: "center",
              }}><Text style={{
                fontSize: 14 * Common.WIDTH_DIVISOR,
                color: "#ffffff"
              }}>
                  {order.image ? "Reupload Image" : "Upload Image"}
                </Text></View>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => { finishOnPress(order) }} disabled={renderStatus(order.status) == "Deliveried"}>
              <View style={{
                borderRadius: 10 * Common.WIDTH_DIVISOR,
                backgroundColor: !order.status ? "#ea9b8e" : "#b6b6b6",
                width: 120 * Common.WIDTH_DIVISOR,
                height: 40 * Common.WIDTH_DIVISOR,
                justifyContent: "center",
                alignSelf: "flex-end",
                marginLeft: 20 * Common.WIDTH_DIVISOR,
                marginBottom: 15 * Common.WIDTH_DIVISOR,
                alignItems: "center",
              }}><Text style={{
                fontSize: 14 * Common.WIDTH_DIVISOR,
                color: "#ffffff"
              }}>
                  {renderStatus(order.status)}
                </Text></View>
            </TouchableOpacity>
          </View >


        </View >
      )
    }

    let rendered = (<>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={{
          fontSize: 16 * Common.WIDTH_DIVISOR,
          fontWeight: "normal",
          fontStyle: "normal",
          textAlign: "left",
          marginLeft: 15 * Common.WIDTH_DIVISOR,
          marginTop: 20 * Common.WIDTH_DIVISOR,
          marginBottom: 9 * Common.WIDTH_DIVISOR,
          color: "#3f3f3f"
        }}>{"#" + item.orderNumber}</Text>
        <Text style={{
          fontSize: 12 * Common.WIDTH_DIVISOR,
          fontWeight: "normal",
          fontStyle: "normal",
          textAlign: "right",
          marginTop: 20 * Common.WIDTH_DIVISOR,
          marginBottom: 9 * Common.WIDTH_DIVISOR,
          marginRight: 20 * Common.WIDTH_DIVISOR,
          color: "#868686"
        }}>
          {moment(item.createdAt).format('ll')}
        </Text>
      </View>
      {renderedCards}
    </>)
    return rendered
  }
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {user ? (
          <View>
            <View
              style={{
                marginTop: 20 * Common.WIDTH_DIVISOR,
                flexDirection: "row",
                alignItems: "center",
                width: SCREEN_WIDTH - 50,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity onPress={() => { }}>
                <View style={styles.loginButton}>
                  <Text style={styles.loginText}>
                    {user.name ? user.name : "Welcome User"}
                  </Text>
                </View>
              </TouchableOpacity>
              {/* 
              <View style={styles.buttonGroup}>
                <TouchableOpacity onPress={() =>{}}>
                  <View style={styles.accountButton}>
                    <Image
                      style={styles.icon}
                      source={require("../assets/HomeTab/AccountIcon.png")}
                    />
                    <Text>My Account</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.radioButtonRow}></View>
              </View> */}
            </View>
            <View style={{
              marginLeft: 20 * Common.WIDTH_DIVISOR,
              width: 300 * Common.WIDTH_DIVISOR,
              marginVertical: 10 * Common.WIDTH_DIVISOR,
              flexDirection: "row",
              alignItems: "center"
            }}>
              <Text>Delivery Date:</Text>

              {/* <TextInput
                style={{
                  color: "#007BC3",
                  marginLeft: 20 * Common.WIDTH_DIVISOR,
                  height: 30 * Common.WIDTH_DIVISOR,
                }}
                onChangeText={dateOnChange}
                value={text}
              /> */}
              <TouchableOpacity onPress={() => {
                setDate(date.add(-1, 'd'))
                fetchDriverOrders()
              }}>
                <Text style={{
                  marginLeft: 10 * Common.WIDTH_DIVISOR,
                  color: "#007BC3",
                  textDecorationLine: 'underline',
                }}>Previous</Text>
              </TouchableOpacity>
              <Text style={{
                marginLeft: 10 * Common.WIDTH_DIVISOR,
                // color: "#007BC3",
              }}>{date.format("YYYY-MM-DD")}</Text>
              <TouchableOpacity onPress={() => {
                setDate(date.add(1, 'd'))
                fetchDriverOrders()
              }}>
                <Text style={{
                  marginLeft: 10 * Common.WIDTH_DIVISOR,
                  color: "#007BC3",
                  textDecorationLine: 'underline',
                }}>Next</Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <TouchableOpacity onPress={() => {
            navigation.navigate('Login')
          }}>
            <View style={styles.loginButton}>
              <Text style={styles.loginText}>Sign In</Text>
              <Image source={require("../assets/HomeTab/SignInIcon.png")} />
            </View>
          </TouchableOpacity>
        )}

      </View>

      {/* {DateTimePicker()} */}

      {/* <DatePicker
        mode="date"
        textColor="#000000"
        modal
        open={this.state.open}
        date={this.state.date}
        onConfirm={this.onConfirm}
        onCancel={() => {
          this.setState({
            open: false,
          })
        }}
      /> */}

      <FlatList
        style={{ backgroundColor: COLORS.GRAY_1 }}
        data={orders}
        renderItem={renderItem}
        keyExtractor={(item) => item._id}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={() => <View>
          <Text style={{
            alignSelf: 'center',
            marginTop: 100,
            fontSize: 24 * Common.WIDTH_DIVISOR,
            color: 'rgb(74,74,74)'
          }}
          >
            No Orders
          </Text>
        </View>}
      />
    </View>
  );
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    width: SCREEN_WIDTH,
    backgroundColor: "#F5F5F5",
  },

  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    paddingTop: 0,
    paddingBottom: 0,
    borderBottomColor: "#F6D9C4",
    borderBottomWidth: 2,
  },

  loginButton: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 24,
  },

  loginText: {
    ...TEXT_STYLES.FONT_TYPE_6,
  },

  accountButton: {
    padding: 6,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 2,
  },

  messageButton: {
    padding: 6,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 4,
  },

  icon: {
    marginVertical: 4,
    marginHorizontal: 8,
  },

  buttonGroup: {
    flexDirection: "row",
    paddingLeft: 12,
    alignItems: "center",
  },

  booking: {
    width: 120,
    height: 40,
    borderRadius: 10,
    backgroundColor: COLORS.MAIN,
    alignItems: "center",
    justifyContent: "center"
  },

  loadingIndicatorContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});



const bookingDetailStyles = StyleSheet.create({
  cardBG: {
    marginHorizontal: 15 * Common.WIDTH_DIVISOR,
    marginBottom: 20 * Common.WIDTH_DIVISOR,
    backgroundColor: "white",
    borderRadius: 4,
    shadowColor: "rgba(36, 36, 36, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowRadius: 15,
    shadowOpacity: 1
  },
  divider: {
    height: 1,
    marginBottom: 20,
    height: 1,
    borderRadius: 4,
    borderStyle: "solid",
    borderWidth: 0.6,
    borderColor: "#ebebeb"
  },
  title: {
    marginHorizontal: 10 * Common.WIDTH_DIVISOR,
    marginTop: 20 * Common.WIDTH_DIVISOR,
    marginBottom: 12,
    fontSize: 14 * Common.WIDTH_DIVISOR,
    fontWeight: "bold"
  },
  textItem: {
    flexDirection: "row",
    marginHorizontal: 10 * Common.WIDTH_DIVISOR,
    marginVertical: 10 * Common.WIDTH_DIVISOR,
  },
  text: {
    fontSize: 12 * Common.WIDTH_DIVISOR,
    marginRight: 20 * Common.WIDTH_DIVISOR,
    width: 300 * Common.WIDTH_DIVISOR,
  },
  titleText: {
    fontWeight: "bold",
    fontSize: 12 * Common.WIDTH_DIVISOR,
  }
})