import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import React, { useRef, useState, useContext, useEffect } from "react";

import { DeviceEventEmitter } from 'react-native';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';


import Loading from './Util/loading'


global.user = null;
global.jwt = null;
export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [loading, setLoading] = useState({})

  const loadingtRef = useRef();

  useEffect(() => {
    DeviceEventEmitter.addListener('showLoading', () => {
      loadingtRef.current.show()
    })
    DeviceEventEmitter.addListener('closeLoading', () => {
      loadingtRef.current.close()
    })
  }, []);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
        <Loading
          ref={loadingtRef}
          hide={true}
        />
      </SafeAreaProvider>
    );
  }
}
